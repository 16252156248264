<template>
  <div>
    <b-alert variant="danger" :show="businessData === undefined">
      <h4 class="alert-heading">Error fetching invoice data</h4>
      <div class="alert-body">
        No business found with this business id. Check
        <b-link class="alert-link" :to="{ name: 'businesses' }">
          Business List
        </b-link>
        for other businesss.
      </div>
    </b-alert>

    <b-row v-if="businessData" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h1 class="text-primary">
                    {{ businessData.name }}
                  </h1>
                </div>
                <h3 class="text-primary">Registration Number: <strong>{{ businessData.registration_number }}</strong></h3>
                <h3 class="text-primary">KRA PIN: <strong>{{ businessData.kra_pin }}</strong></h3>

                <h4 class="text-primary">
                  {{ businessData.category.name }}
                </h4>
                
                <div class="d-flex">
                  <p
                    class="card-text mb-25 mr-1"
                    v-for="jurisdiction in businessData.jurisdiction"
                    :key="jurisdiction"
                  >
                    {{ jurisdiction }},
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card v-if="businessData.licenses.length">
          <!-- Licenses: Table -->
          <b-table
              responsive
              :items="businessData.licenses"
              :fields="['license_id', 'tariff', 'issued_on', 'valid_until']"
            >
            <template #cell(tariff)="data">
                <b-card-text class="text-nowrap">
                  {{ shortenTariff(data.item.tariff.name) }} - Ksh.{{ data.item.tariff.price }}
                </b-card-text>
              </template>
              <template #cell(issued_on)="data">
                <b-card-text class="text-nowrap">
                  {{ moment(data.item.updated_at).format("dddd, MMMM Do YYYY") }}
                </b-card-text>
              </template>
              <template #cell(valid_until)="data">
                <b-card-text class="text-nowrap">
                  {{ moment(data.item.valid_until).format("dddd, MMMM Do YYYY") }}
                </b-card-text>
              </template>
            </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BFormInput,
  BModal,
  BFormGroup,
  BSpinner,
  BTable,
} from "bootstrap-vue";
import useBusinessList from "./useBusinessList";
import businessStoreModule from "./businessStoreModule";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormInput,
    BModal,
    BFormGroup,
    BSpinner,
    BTable,
  },
  setup() {
    const businessData = ref(null);

    // Use toast
    const toast = useToast();

    const BUSINESS_APP_STORE_MODULE_NAME = "business";

    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME))
      store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, businessStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME))
        store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("business/fetchBusiness", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        businessData.value = response.data.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          businessData.value = undefined;
        }
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching business",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    const shortenTariff = (tariff) => {
      if(tariff.length > 10) {
        return tariff.substr(0, 10) + '...'
      } else {
        return tariff
      }
    }

    return {
      businessData,

      shortenTariff,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss"></style>
